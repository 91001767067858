import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_result, {
        status: "500",
        title: "Desculpe, não foi possível processar sua solicitação no momento.",
        "sub-title": "Aguarde alguns instantes e tente novamente..."
      }, {
        extra: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _ctx.voltar,
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Voltar")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ])
  ]))
}